.PVBlock {
    justify-content: center;
    border-radius: 20px;
    /* border: 1px solid pink; */
    background-color: whitesmoke;
    margin: auto;
    height: auto;
    width: 60%;
    margin-bottom: 2%;
}

.PVBlockHeader {
    display: flex;
    flex-direction: row;
    background-color: #13697E;
    color: white;
    margin-top: 0%;
    border-radius: 20px 20px 0 0;
    justify-content: center;
    height: 40%;
    padding: 4%;
}

.HHID {
    width: 60%;
    display: flex;
    flex-direction: column;
}

.Title {
    font-size: 18px;
    font-weight: bold;
}

.STitle {
    font-size: 16px;
}

.HeaderButtons {
    display: flex;
    flex-direction: row;
    padding: 2%;    
}

.ActionSelect{
    margin-right: 10px;
}

.PVBLOCKBODY {
    display: flex;
    flex-direction: column;

}